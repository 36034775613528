<template>
  <b-card>
    <b-row class="mb-50">
      <b-col md="4"
        ><p class="h2">{{ $t("companyName") }} :</p></b-col
      >
      <b-col md="4"
        ><span class="h4">{{ companyName }}</span></b-col
      >
    </b-row>
    <b-row class="mb-50">
      <b-col md="4"
        ><p class="h2">{{ $t("planName") }} :</p></b-col
      >
      <b-col md="4"
        ><span class="h4">{{
          isRight ? lookups.planArabicName : lookups.planEnglishName
        }}</span></b-col
      >
    </b-row>
    <b-row class="mb-50">
      <b-col md="4"
        ><p class="h2">{{ $t("subscriptionType") }} :</p></b-col
      >
      <b-col md="4"
        ><span class="h4">{{ subscriptionType }}</span></b-col
      >
    </b-row>
    <b-row class="mb-50">
      <b-col md="4"
        ><p class="h2">{{ $t("commercialRegisterNumber") }} :</p></b-col
      >
      <b-col md="4"
        ><span class="h4">{{ registrationNumber }}</span></b-col
      >
    </b-row>
    <b-row class="mb-50">
      <b-col md="4"
        ><p class="h2">{{ $t("taxNumber") }} :</p></b-col
      >
      <b-col md="4"
        ><span class="h4">{{ taxRegistrationNumber }}</span></b-col
      >
    </b-row>
    <b-row class="mb-50">
      <b-col md="4"
        ><p class="h2">{{ $t("startSubscraptionDate") }} :</p></b-col
      >
      <b-col md="4"
        ><span class="h4">{{ getDate(subscriptionStartDate) }}</span></b-col
      >
    </b-row>
    <b-row class="mb-50">
      <b-col md="4"
        ><p class="h2">{{ $t("endSubscraptionDate") }} :</p></b-col
      >
      <b-col md="4"
        ><span class="h4">{{ getDate(subscriptionEndDate) }}</span></b-col
      >
    </b-row>
    <b-row class="mb-50">
      <b-col md="4"
        ><p class="h2">{{ $t("remainder") }} :</p></b-col
      >
      <b-col md="4"
        ><span class="h4"
          ><flip-countdown
            class="col col-md-1 w-auto ml-0"
            :deadline="`${getDate(profile.subscriptionEndDate)} ${getTime(
              profile.subscriptionEndDate
            )}`"
            :labels="counterLables"
            :showHours="false"
            :showSeconds="false"
            :showMinutes="false"
            countdownSize="25px"
          ></flip-countdown></span
      ></b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col
        lg="4"
        v-if="lookups.planDealers > 0"
        order-lg="2"
        order="1"
        class="d-flex justify-content-between shadow flex-column"
      >
        <b-card no-body class="card-statistics p-50">
          <b-card-header class="pl-0">
            <b-card-title
              >{{ $t("studentsData") }}
              <feather-icon
                icon="RefreshCwIcon"
                class="mx-25 clickable"
                @click="
                  () => {
                    flipDealer = !flipDealer;
                  }
                "
            /></b-card-title>
          </b-card-header>
          <h2 v-if="lookups.bankBalance && lookups.bankBalance.length < 1">
            {{ $t("notFoundData") }}
          </h2>
          <transition name="flip">
            <div v-bind:key="flipDealer" class="card">
              <div v-if="!flipDealer" name="front">
                <b-row class="pt-2">
                  <b-col md="4">
                    <b-card-title class="mb-25" style="font-size: 1rem">
                      {{ $t("actual") }}
                    </b-card-title>
                    <b-card-text class="mb-50" style="font-size: 1.5rem">
                      {{ lookups.planDealers }}
                    </b-card-text>
                  </b-col>
                  <b-col md="4">
                    <b-card-title class="mb-25" style="font-size: 1rem">
                      {{ $t("usage") }}
                    </b-card-title>
                    <b-card-text class="mb-50" style="font-size: 1.5rem">
                      {{ lookups.usedDealers }}
                    </b-card-text>
                  </b-col>
                  <b-col md="4">
                    <b-card-title class="mb-25" style="font-size: 1rem">
                      {{ $t("remaindered") }}
                    </b-card-title>
                    <b-card-text class="mb-50" style="font-size: 1.5rem">
                      {{ lookups.planDealers - lookups.usedDealers }}
                    </b-card-text>
                  </b-col>
                </b-row>
                <b-row class="pt-4">
                  <b-col md="12">
                    <b-progress
                      show-value
                      :value="lookups.usedDealers"
                      :max="lookups.planDealers"
                      height="15px"
                      variant="dealerbg"
                    />
                  </b-col>
                </b-row>
              </div>
              <div v-else name="back">
                <vue-apex-charts
                  ref="realtimeChart"
                  type="donut"
                  height="250"
                  :options="dealersData.chartOptions"
                  :series="dealersData.series"
                  :dir="isRight ? 'rtl' : 'ltr'"
                />
              </div>
            </div>
          </transition>
        </b-card>
      </b-col>
      <b-col
        lg="4"
        v-if="lookups.planBranches > 0"
        order-lg="2"
        order="1"
        class="d-flex justify-content-between shadow flex-column col-md-12"
      >
        <b-card no-body class="card-statistics p-50">
          <b-card-header class="pl-0">
            <b-card-title
              >{{ $t("branchesData") }}
              <feather-icon
                icon="RefreshCwIcon"
                class="mx-25 clickable"
                @click="
                  () => {
                    flipBranches = !flipBranches;
                  }
                "
            /></b-card-title>
          </b-card-header>
          <h2 v-if="lookups.bankBalance && lookups.bankBalance.length < 1">
            {{ $t("notFoundData") }}
          </h2>
          <transition name="flip">
            <div v-bind:key="flipBranches" class="card">
              <div v-if="flipBranches" name="back">
                <b-row class="pt-2">
                  <b-col md="4">
                    <b-card-title class="mb-25" style="font-size: 1rem">
                      {{ $t("actual") }}
                    </b-card-title>
                    <b-card-text class="mb-50" style="font-size: 1.5rem">
                      {{ lookups.planBranches }}
                    </b-card-text>
                  </b-col>
                  <b-col md="4">
                    <b-card-title class="mb-25" style="font-size: 1rem">
                      {{ $t("usage") }}
                    </b-card-title>
                    <b-card-text class="mb-50" style="font-size: 1.5rem">
                      {{ lookups.usedBranches }}
                    </b-card-text>
                  </b-col>
                  <b-col md="4">
                    <b-card-title class="mb-25" style="font-size: 1rem">
                      {{ $t("remaindered") }}
                    </b-card-title>
                    <b-card-text class="mb-50" style="font-size: 1.5rem">
                      {{ lookups.planBranches - lookups.usedBranches }}
                    </b-card-text>
                  </b-col>
                </b-row>
                <b-row class="pt-4">
                  <b-col md="12">
                    <b-progress
                      show-value
                      :value="lookups.usedBranches"
                      :max="lookups.planBranches"
                      height="15px"
                      variant="orange"
                    />
                  </b-col>
                </b-row>
              </div>
              <div v-else name="front">
                <vue-apex-charts
                  ref="realtimeChart"
                  type="donut"
                  height="250"
                  :options="branchesData.chartOptions"
                  :series="branchesData.series"
                  :dir="isRight ? 'rtl' : 'ltr'"
                />
              </div>
            </div>
          </transition>
        </b-card>
      </b-col>
      <b-col
        lg="4"
        v-if="lookups.planStores > 0"
        order-lg="3"
        order="3"
        class="d-flex justify-content-between shadow flex-column col-md-12"
      >
        <!-- <b-card no-body class="card-statistics p-50">
          <b-card-header class="pl-0">
            <b-card-title
              >{{ $t("storesData") }}
              <feather-icon
                icon="RefreshCwIcon"
                class="mx-25 clickable"
                @click="
                  () => {
                    flipStore = !flipStore;
                  }
                "
            /></b-card-title>
          </b-card-header>
          <transition name="flip">
            <div v-bind:key="flipStore" class="card">
              <div v-if="flipStore" name="back">
                <b-row class="pt-2">
                  <b-col md="4">
                    <b-card-title class="mb-25" style="font-size: 1rem">
                      {{ $t("actual") }}
                    </b-card-title>
                    <b-card-text class="mb-50" style="font-size: 1.5rem">
                      {{ lookups.planStores }}
                    </b-card-text>
                  </b-col>
                  <b-col md="4">
                    <b-card-title class="mb-25" style="font-size: 1rem">
                      {{ $t("usage") }}
                    </b-card-title>
                    <b-card-text class="mb-50" style="font-size: 1.5rem">
                      {{ lookups.usedStores }}
                    </b-card-text>
                  </b-col>
                  <b-col md="4">
                    <b-card-title class="mb-25" style="font-size: 1rem">
                      {{ $t("remaindered") }}
                    </b-card-title>
                    <b-card-text class="mb-50" style="font-size: 1.5rem">
                      {{ lookups.planStores - lookups.usedStores }}
                    </b-card-text>
                  </b-col>
                </b-row>
                <b-row class="pt-4">
                  <b-col md="12">
                    <b-progress
                      show-value
                      :value="lookups.usedStores"
                      :max="lookups.planStores"
                      height="15px"
                      variant="burble"
                    />
                  </b-col>
                </b-row>
              </div>
              <div v-else name="front">
                <vue-apex-charts
                  ref="realtimeChart"
                  type="donut"
                  height="250"
                  :options="storesData.chartOptions"
                  :series="storesData.series"
                  :dir="isRight ? 'rtl' : 'ltr'"
                />
              </div>
            </div>
          </transition>
        </b-card> -->
      </b-col>
      <!-- <b-col
        lg="4"
        v-if="lookups.planItems > 0"
        order-lg="2"
        order="1"
        class="d-flex justify-content-between shadow flex-column"
      >
        <b-card no-body class="card-statistics p-50">
          <b-card-header class="pl-0">
            <b-card-title
              >{{ $t("itemsData") }}
              <feather-icon
                icon="RefreshCwIcon"
                class="mx-25 clickable"
                @click="
                  () => {
                    flipItem = !flipItem;
                  }
                "
            /></b-card-title>
          </b-card-header>
          <transition name="flip">
            <div v-bind:key="flipItem" class="card">
              <div v-if="!flipItem" name="front">
                <b-row class="pt-2">
                  <b-col md="4">
                    <b-card-title class="mb-25" style="font-size: 1rem">
                      {{ $t("actual") }}
                    </b-card-title>
                    <b-card-text class="mb-50" style="font-size: 1.5rem">
                      {{ lookups.planItems }}
                    </b-card-text>
                  </b-col>
                  <b-col md="4">
                    <b-card-title class="mb-25" style="font-size: 1rem">
                      {{ $t("usage") }}
                    </b-card-title>
                    <b-card-text class="mb-50" style="font-size: 1.5rem">
                      {{ lookups.usedItems }}
                    </b-card-text>
                  </b-col>
                  <b-col md="4">
                    <b-card-title class="mb-25" style="font-size: 1rem">
                      {{ $t("remaindered") }}
                    </b-card-title>
                    <b-card-text class="mb-50" style="font-size: 1.5rem">
                      {{ lookups.planItems - lookups.usedItems }}
                    </b-card-text>
                  </b-col>
                </b-row>
                <b-row class="pt-4">
                  <b-col md="12">
                    <b-progress
                      show-value
                      :value="lookups.usedItems"
                      :max="lookups.planItems"
                      height="15px"
                      variant="itembg"
                    />
                  </b-col>
                </b-row>
              </div>
              <div v-else name="back">
                <vue-apex-charts
                  ref="realtimeChart"
                  type="donut"
                  height="250"
                  :options="itemsData.chartOptions"
                  :series="itemsData.series"
                  :dir="isRight ? 'rtl' : 'ltr'"
                />
              </div>
            </div>
          </transition>
        </b-card>
      </b-col> -->
      <!-- <b-col
        lg="4"
        v-if="lookups.planSales > 0"
        order-lg="2"
        order="1"
        class="d-flex justify-content-between shadow flex-column col-md-12"
      >
        <b-card no-body class="card-statistics p-50">
          <b-card-header class="pl-0">
            <b-card-title
              >{{ $t("salesData") }}
              <feather-icon
                icon="RefreshCwIcon"
                class="mx-25 clickable"
                @click="
                  () => {
                    flipSales = !flipSales;
                  }
                "
            /></b-card-title>
          </b-card-header>
          <transition name="flip">
            <div v-bind:key="flipSales" class="card">
              <div v-if="!flipSales" name="front">
                <b-row class="pt-2">
                  <b-col md="4">
                    <b-card-title class="mb-25" style="font-size: 1rem">
                      {{ $t("actual") }}
                    </b-card-title>
                    <b-card-text class="mb-50" style="font-size: 1.5rem">
                      {{ lookups.planSales }}
                    </b-card-text>
                  </b-col>
                  <b-col md="4">
                    <b-card-title class="mb-25" style="font-size: 1rem">
                      {{ $t("usage") }}
                    </b-card-title>
                    <b-card-text class="mb-50" style="font-size: 1.5rem">
                      {{ lookups.usedSales }}
                    </b-card-text>
                  </b-col>
                  <b-col md="4">
                    <b-card-title class="mb-25" style="font-size: 1rem">
                      {{ $t("remaindered") }}
                    </b-card-title>
                    <b-card-text class="mb-50" style="font-size: 1.5rem">
                      {{ lookups.planSales - lookups.usedSales }}
                    </b-card-text>
                  </b-col>
                </b-row>
                <b-row class="pt-4">
                  <b-col md="12">
                    <b-progress
                      show-value
                      :value="lookups.usedSales"
                      :max="lookups.planSales"
                      height="15px"
                      variant="salesbg"
                    />
                  </b-col>
                </b-row>
              </div>
              <div v-else name="back">
                <vue-apex-charts
                  ref="realtimeChart"
                  type="donut"
                  height="250"
                  :options="salesData.chartOptions"
                  :series="salesData.series"
                  :dir="isRight ? 'rtl' : 'ltr'"
                />
              </div>
            </div>
          </transition>
        </b-card>
      </b-col> -->
      <!-- <b-col
        lg="4"
        v-if="lookups.planPurchase > 0"
        order-lg="3"
        order="3"
        class="d-flex justify-content-between shadow flex-column col-md-12"
      >
        <b-card no-body class="card-statistics p-50">
          <b-card-header class="pl-0">
            <b-card-title
              >{{ $t("purchaseData") }}
              <feather-icon
                icon="RefreshCwIcon"
                class="mx-25 clickable"
                @click="
                  () => {
                    flipPurchase = !flipPurchase;
                  }
                "
            /></b-card-title>
          </b-card-header>
          <transition name="flip">
            <div v-bind:key="flipPurchase" class="card">
              <div v-if="!flipPurchase" name="front">
                <b-row class="pt-2">
                  <b-col md="4">
                    <b-card-title class="mb-25" style="font-size: 1rem">
                      {{ $t("actual") }}
                    </b-card-title>
                    <b-card-text class="mb-50" style="font-size: 1.5rem">
                      {{ lookups.planPurchase }}
                    </b-card-text>
                  </b-col>
                  <b-col md="4">
                    <b-card-title class="mb-25" style="font-size: 1rem">
                      {{ $t("usage") }}
                    </b-card-title>
                    <b-card-text class="mb-50" style="font-size: 1.5rem">
                      {{ lookups.usedPurchase }}
                    </b-card-text>
                  </b-col>
                  <b-col md="4">
                    <b-card-title class="mb-25" style="font-size: 1rem">
                      {{ $t("remaindered") }}
                    </b-card-title>
                    <b-card-text class="mb-50" style="font-size: 1.5rem">
                      {{ lookups.planPurchase - lookups.usedPurchase }}
                    </b-card-text>
                  </b-col>
                </b-row>
                <b-row class="pt-4">
                  <b-col md="12">
                    <b-progress
                      show-value
                      :value="lookups.usedPurchase"
                      :max="lookups.planPurchase"
                      height="15px"
                      variant="purchbg"
                    />
                  </b-col>
                </b-row>
              </div>
              <div v-else name="back">
                <vue-apex-charts
                  ref="realtimeChart"
                  type="donut"
                  height="250"
                  :options="purchaseData.chartOptions"
                  :series="purchaseData.series"
                  :dir="isRight ? 'rtl' : 'ltr'"
                />
              </div>
            </div>
          </transition>
        </b-card>
      </b-col> -->
      <!-- <b-col
        lg="4"
        v-if="lookups.planFinancialTransaction > 0"
        order-lg="2"
        order="1"
        class="d-flex justify-content-between shadow flex-column"
      >
        <b-card no-body class="card-statistics p-50">
          <b-card-header class="pl-0">
            <b-card-title
              >{{ $t("financialTransactionData") }}
              <feather-icon
                icon="RefreshCwIcon"
                class="mx-25 clickable"
                @click="
                  () => {
                    flipFinancialTransaction = !flipFinancialTransaction;
                  }
                "
            /></b-card-title>
          </b-card-header>
          <transition name="flip">
            <div v-bind:key="flipFinancialTransaction" class="card">
              <div v-if="!flipFinancialTransaction" name="front">
                <b-row class="pt-2">
                  <b-col md="4">
                    <b-card-title class="mb-25" style="font-size: 1rem">
                      {{ $t("actual") }}
                    </b-card-title>
                    <b-card-text class="mb-50" style="font-size: 1.5rem">
                      {{ lookups.planFinancialTransaction }}
                    </b-card-text>
                  </b-col>
                  <b-col md="4">
                    <b-card-title class="mb-25" style="font-size: 1rem">
                      {{ $t("usage") }}
                    </b-card-title>
                    <b-card-text class="mb-50" style="font-size: 1.5rem">
                      {{ lookups.usedFinancialTransaction }}
                    </b-card-text>
                  </b-col>
                  <b-col md="4">
                    <b-card-title class="mb-25" style="font-size: 1rem">
                      {{ $t("remaindered") }}
                    </b-card-title>
                    <b-card-text class="mb-50" style="font-size: 1.5rem">
                      {{
                        lookups.planFinancialTransaction -
                        lookups.usedFinancialTransaction
                      }}
                    </b-card-text>
                  </b-col>
                </b-row>
                <b-row class="pt-4">
                  <b-col md="12">
                    <b-progress
                      show-value
                      :value="lookups.usedFinancialTransaction"
                      :max="lookups.planFinancialTransaction"
                      height="15px"
                      variant="finalbg"
                    />
                  </b-col>
                </b-row>
              </div>
              <div v-else name="back">
                <vue-apex-charts
                  ref="realtimeChart"
                  type="donut"
                  height="250"
                  :options="financialTransactionData.chartOptions"
                  :series="financialTransactionData.series"
                  :dir="isRight ? 'rtl' : 'ltr'"
                />
              </div>
            </div>
          </transition>
        </b-card>
      </b-col> -->
      <!-- <b-col
        lg="4"
        v-if="lookups.planVouchers > 0"
        order-lg="2"
        order="1"
        class="d-flex justify-content-between shadow flex-column col-md-12"
      >
        <b-card no-body class="card-statistics p-50">
          <b-card-header class="pl-0">
            <b-card-title
              >{{ $t("vouchersData") }}
              <feather-icon
                icon="RefreshCwIcon"
                class="mx-25 clickable"
                @click="
                  () => {
                    flipVouchers = !flipVouchers;
                  }
                "
            /></b-card-title>
          </b-card-header>
          <transition name="flip">
            <div v-bind:key="flipVouchers" class="card">
              <div v-if="!flipVouchers" name="front">
                <b-row class="pt-2">
                  <b-col md="4">
                    <b-card-title class="mb-25" style="font-size: 1rem">
                      {{ $t("actual") }}
                    </b-card-title>
                    <b-card-text class="mb-50" style="font-size: 1.5rem">
                      {{ lookups.planVouchers }}
                    </b-card-text>
                  </b-col>
                  <b-col md="4">
                    <b-card-title class="mb-25" style="font-size: 1rem">
                      {{ $t("usage") }}
                    </b-card-title>
                    <b-card-text class="mb-50" style="font-size: 1.5rem">
                      {{ lookups.usedVouchers }}
                    </b-card-text>
                  </b-col>
                  <b-col md="4">
                    <b-card-title class="mb-25" style="font-size: 1rem">
                      {{ $t("remaindered") }}
                    </b-card-title>
                    <b-card-text class="mb-50" style="font-size: 1.5rem">
                      {{ lookups.planVouchers - lookups.usedVouchers }}
                    </b-card-text>
                  </b-col>
                </b-row>
                <b-row class="pt-4">
                  <b-col md="12">
                    <b-progress
                      show-value
                      :value="lookups.usedVouchers"
                      :max="lookups.planVouchers"
                      height="15px"
                      variant="voucherbg"
                    />
                  </b-col>
                </b-row>
              </div>
              <div v-else name="back">
                <vue-apex-charts
                  ref="realtimeChart"
                  type="donut"
                  height="250"
                  :options="vouchersData.chartOptions"
                  :series="vouchersData.series"
                  :dir="isRight ? 'rtl' : 'ltr'"
                />
              </div>
            </div>
          </transition>
        </b-card>
      </b-col> -->
      <b-col
        lg="4"
        v-if="lookups.planUsers > 0"
        order-lg="3"
        order="3"
        class="d-flex justify-content-between shadow flex-column col-md-12"
      >
        <b-card no-body class="card-statistics p-50">
          <b-card-header class="pl-0">
            <b-card-title
              >{{ $t("usersData") }}
              <feather-icon
                icon="RefreshCwIcon"
                class="mx-25 clickable"
                @click="
                  () => {
                    flipUsers = !flipUsers;
                  }
                "
            /></b-card-title>
          </b-card-header>
          <transition name="flip">
            <div v-bind:key="flipUsers" class="card">
              <div v-if="flipUsers" name="back">
                <b-row class="pt-2">
                  <b-col md="4">
                    <b-card-title class="mb-25" style="font-size: 1rem">
                      {{ $t("actual") }}
                    </b-card-title>
                    <b-card-text class="mb-50" style="font-size: 1.5rem">
                      {{ lookups.planUsers }}
                    </b-card-text>
                  </b-col>
                  <b-col md="4">
                    <b-card-title class="mb-25" style="font-size: 1rem">
                      {{ $t("usage") }}
                    </b-card-title>
                    <b-card-text class="mb-50" style="font-size: 1.5rem">
                      {{ lookups.usedUsers }}
                    </b-card-text>
                  </b-col>
                  <b-col md="4">
                    <b-card-title class="mb-25" style="font-size: 1rem">
                      {{ $t("remaindered") }}
                    </b-card-title>
                    <b-card-text class="mb-50" style="font-size: 1.5rem">
                      {{ lookups.planUsers - lookups.usedUsers }}
                    </b-card-text>
                  </b-col>
                </b-row>
                <b-row class="pt-4">
                  <b-col md="12">
                    <b-progress
                      show-value
                      :value="lookups.usedUsers"
                      :max="lookups.planUsers"
                      height="15px"
                      variant="darkblue"
                    />
                  </b-col>
                </b-row>
              </div>
              <div v-else name="front">
                <vue-apex-charts
                  ref="realtimeChart"
                  type="donut"
                  height="250"
                  :options="usersData.chartOptions"
                  :series="usersData.series"
                  :dir="isRight ? 'rtl' : 'ltr'"
                />
              </div>
            </div>
          </transition>
        </b-card>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { BProgress } from 'bootstrap-vue';
import VueApexCharts from 'vue-apexcharts';
import FlipCountdown from 'vue2-flip-countdown';

export default {
  components: {
    FlipCountdown,
    BProgress,
    VueApexCharts,
  },
  data() {
    return {
      counterLables: {
        days: 'يوم',
        hours: 'ساعه',
        minutes: 'دقيقه',
        seconds: 'ثانيه',
      },
      flipBranches: false,
      flipStore: false,
      flipDealer: false,
      flipItem: false,
      flipPurchase: false,
      flipSales: false,
      flipUsers: false,
      flipVouchers: false,
      flipFinancialTransaction: false,
      dealersData: {
        series: [10, 20],
        chartOptions: {
          chart: {
            width: 380,
            type: 'pie',
            size: 500,
          },
          labels: ['one', 'two'],
          responsive: [
            {
              breakpoint: 480,
              options: {
                plotOptions: {
                  pie: {
                    donut: {
                      size: '100%',
                    },
                  },
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
          ],
        },
      },
      itemsData: {
        series: [10, 20],
        chartOptions: {
          chart: {
            width: 380,
            type: 'pie',
            size: 500,
          },
          labels: ['one', 'two'],
          responsive: [
            {
              breakpoint: 480,
              options: {
                plotOptions: {
                  pie: {
                    donut: {
                      size: '100%',
                    },
                  },
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
          ],
        },
      },
      storesData: {
        series: [10, 20],
        chartOptions: {
          chart: {
            width: 380,
            type: 'pie',
            size: 500,
          },
          labels: ['one', 'two'],
          responsive: [
            {
              breakpoint: 480,
              options: {
                plotOptions: {
                  pie: {
                    donut: {
                      size: '100%',
                    },
                  },
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
          ],
        },
      },
      salesData: {
        series: [10, 20],
        chartOptions: {
          chart: {
            width: 380,
            type: 'pie',
            size: 500,
          },
          labels: ['one', 'two'],
          responsive: [
            {
              breakpoint: 480,
              options: {
                plotOptions: {
                  pie: {
                    donut: {
                      size: '100%',
                    },
                  },
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
          ],
        },
      },
      purchaseData: {
        series: [10, 20],
        chartOptions: {
          chart: {
            width: 380,
            type: 'pie',
            size: 500,
          },
          labels: ['one', 'two'],
          responsive: [
            {
              breakpoint: 480,
              options: {
                plotOptions: {
                  pie: {
                    donut: {
                      size: '100%',
                    },
                  },
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
          ],
        },
      },
      financialTransactionData: {
        series: [10, 20],
        chartOptions: {
          chart: {
            width: 380,
            type: 'pie',
            size: 500,
          },
          labels: ['one', 'two'],
          responsive: [
            {
              breakpoint: 480,
              options: {
                plotOptions: {
                  pie: {
                    donut: {
                      size: '100%',
                    },
                  },
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
          ],
        },
      },
      vouchersData: {
        series: [10, 20],
        chartOptions: {
          chart: {
            width: 380,
            type: 'pie',
            size: 500,
          },
          labels: ['one', 'two'],
          responsive: [
            {
              breakpoint: 480,
              options: {
                plotOptions: {
                  pie: {
                    donut: {
                      size: '100%',
                    },
                  },
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
          ],
        },
      },
      usersData: {
        series: [10, 20],
        chartOptions: {
          chart: {
            width: 380,
            type: 'pie',
            size: 500,
          },
          labels: ['one', 'two'],
          responsive: [
            {
              breakpoint: 480,
              options: {
                plotOptions: {
                  pie: {
                    donut: {
                      size: '100%',
                    },
                  },
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
          ],
        },
      },
      branchesData: {
        series: [10, 20],
        chartOptions: {
          chart: {
            width: 380,
            type: 'pie',
            size: 500,
          },
          labels: ['one', 'two'],
          responsive: [
            {
              breakpoint: 480,
              options: {
                plotOptions: {
                  pie: {
                    donut: {
                      size: '100%',
                    },
                  },
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
          ],
        },
      },
      lookups: {
        planDealers: 0,
        usedDealers: 0,
        planItems: 0,
        usedItems: 0,
        planStores: 0,
        usedStores: 0,
        planSales: 0,
        usedSales: 0,
        planPurchase: 0,
        usedPurchase: 0,
        planFinancialTransaction: 0,
        usedFinancialTransaction: 0,
        planVouchers: 0,
        usedVouchers: 0,
        planUsers: 0,
        usedUsers: 0,
        planBranches: 0,
        usedBranches: 0
      },
      subscriptionEndDate: '',
      subscriptionStartDate: '',
      subscriptionType: '',
      registrationNumber: '',
      taxRegistrationNumber: '',
      companyName: '',
    };
  },
  mounted() {
    this.subscriptionEndDate = this.profile.subscriptionEndDate;
    this.subscriptionStartDate = this.profile.subscriptionStartDate;
    this.subscriptionType = this.$t('annual');
    this.registrationNumber = this.company.registrationNumber;
    this.taxRegistrationNumber = this.company.taxRegistrationNumber;
    this.companyName = this.isRight
      ? this.company.arabicName
      : this.company.englishName;
    this.getData();
  },
  methods: {
    getData() {
      this.get({ url: 'Subscriptions' }).then((data) => {
        this.lookups = data;
        this.updatechart();
      })
    },
    updatechart() {
      this.dealersData = {
        series: [this.lookups.usedDealers, (this.lookups.planDealers - this.lookups.usedDealers)],
      };
      this.dealersData.chartOptions = {
        labels: [this.$t('usage'), this.$t('remaindered')],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  label: this.$t('actual'),
                  formatter: () => {
                    return this.lookups.planDealers;
                  }
                }
              }
            }
          }
        },
        colors: ['#2E93fA', '#7367f0'],
      };
      this.branchesData = {
        series: [this.lookups.usedBranches, (this.lookups.planBranches - this.lookups.usedBranches)],
      };
      this.branchesData.chartOptions = {
        labels: [this.$t('usage'), this.$t('remaindered')],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  label: this.$t('actual'),
                  formatter: () => {
                    let total = 0;
                    total = this.lookups.planBranches
                    return total;
                  }
                }
              }
            }
          }
        },
        colors: ['#FF9800', '#7367f0'],
      };
      this.storesData = {
        series: [this.lookups.usedStores, (this.lookups.planStores - this.lookups.usedStores)],
      };
      this.storesData.chartOptions = {
        labels: [this.$t('usage'), this.$t('remaindered')],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  label: this.$t('actual'),
                  formatter: () => {
                    return this.lookups.planStores;
                  }
                }
              }
            }
          }
        },
        colors: ['#d9138a', '#7367f0'],
      };
      this.itemsData = {
        series: [this.lookups.usedItems, (this.lookups.planItems - this.lookups.usedItems)],
      };
      this.itemsData.chartOptions = {
        labels: [this.$t('usage'), this.$t('remaindered')],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  label: this.$t('actual'),
                  formatter: () => {
                    return this.lookups.planItems;
                  }
                }
              }
            }
          }
        },
        colors: ['#e2d810', '#7367f0'],
      };
      this.usersData = {
        series: [this.lookups.usedUsers, (this.lookups.planUsers - this.lookups.usedUsers)],
      };
      this.usersData.chartOptions = {
        labels: [this.$t('usage'), this.$t('remaindered')],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  label: this.$t('actual'),
                  formatter: () => {
                    return this.lookups.planUsers;
                  }
                }
              }
            }
          }
        },
        colors: ['#3b4d61', '#7367f0'],
      };
      this.financialTransactionData = {
        series: [this.lookups.usedFinancialTransaction, (this.lookups.planFinancialTransaction - this.lookups.usedFinancialTransaction)],
      };
      this.financialTransactionData.chartOptions = {
        labels: [this.$t('usage'), this.$t('remaindered')],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  label: this.$t('actual'),
                  formatter: () => {
                    return this.lookups.planFinancialTransaction;
                  }
                }
              }
            }
          }
        },
        colors: ['#7a2048', '#7367f0'],
      };
      this.vouchersData = {
        series: [this.lookups.usedVouchers, (this.lookups.planVouchers - this.lookups.usedVouchers)],
      };
      this.vouchersData.chartOptions = {
        labels: [this.$t('usage'), this.$t('remaindered')],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  label: this.$t('actual'),
                  formatter: () => {
                    return this.lookups.planVouchers;
                  }
                }
              }
            }
          }
        },
        colors: ['#d2601a', '#7367f0'],
      };
      this.salesData = {
        series: [this.lookups.usedSales, (this.lookups.planSales - this.lookups.usedSales)],
      };
      this.salesData.chartOptions = {
        labels: [this.$t('usage'), this.$t('remaindered')],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  label: this.$t('actual'),
                  formatter: () => {
                    return this.lookups.planSales;
                  }
                }
              }
            }
          }
        },
        colors: ['#77c593', '#7367f0'],
      };
      this.purchaseData = {
        series: [this.lookups.usedPurchase, (this.lookups.planPurchase - this.lookups.usedPurchase)],
      };
      this.purchaseData.chartOptions = {
        labels: [this.$t('usage'), this.$t('remaindered')],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  label: this.$t('actual'),
                  formatter: () => {
                    return this.lookups.planPurchase;
                  }
                }
              }
            }
          }
        },
        colors: ['#48731d', '#7367f0'],
      };
    }
  }
};
  </script>
  <style>
.bg-orange {
  background-color: #ff9800;
}
.bg-burble {
  background-color: #d9138a;
}
.bg-darkblue {
  background-color: #3b4d61;
}
.bg-lightgreen {
  background-color: #53a204;
}
.bg-darkgreen {
  background-color: #437455;
}
.bg-lightblue {
  background-color: #3e7ca9;
}
.bg-darkorange {
  background-color: #c65822;
}
.bg-darkburble {
  background-color: #64239c;
}
.flip-card__bottom[data-v-cff81de8],
.flip-card__back-bottom[data-v-cff81de8],
.flip-card__bottom-4digits[data-v-cff81de8],
.flip-card__back-bottom-4digits[data-v-cff81de8] {
  color: #f8f8f8 !important;
}
.flip-card__top[data-v-cff81de8],
.flip-card__top-4digits[data-v-cff81de8],
.flip-card__bottom[data-v-cff81de8],
.flip-card__back-bottom[data-v-cff81de8],
.flip-card__back[data-v-cff81de8]::before,
.flip-card__back[data-v-cff81de8]::after {
  color: #f8f8f8 !important;
}
.flip-enter-active {
  transition: all 0.4s ease;
}

.flip-leave-active {
  display: none;
}

.flip-enter,
.flip-leave {
  transform: rotateY(180deg);
  opacity: 0;
}
</style>
